/* TODO: set vars for resued colours  */

.NavbarItems {
    background: rgb(81,226,224);
    background: linear-gradient(90deg, rgba(62, 211, 208, 0.785) 5%, rgba(7,150,255,0.8939950980392157) 50%, rgba(93,57,255,0.8239670868347339) 95%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 1em;
}

.nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1em;
}
.nav-links:hover{
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 1024px) {

    .navbar-logo {
        font-size: 1.5rem;
        margin: 0.5rem 1rem 0 0;
    }
    .NavbarItems {
        position: relative;
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 431px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease; 
    }
    .nav-menu.active {
        background:rgba(93, 57, 255, 0.966);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }
    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }
    .nav-links:hover {
        background-color: rgba(255, 255, 255, 0.25);
        border-radius: 0;
    }
    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }
    .menu-icon {
        display: block;
        position: absolute;
        top: 15%;
        right: 1em;
        transform: translate(-100%, 60%);
        cursor: pointer;
    }
    .fa-times {
        color: #fff;
        font-size: 1em;
    }
    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #fff;
        text-decoration: none;
        font-weight: bolder;
        color: #552fff;
    }
    .nav-links-mobile:hover {
        background-color: rgba(255, 255, 255, 0.25);
        color: #fff;
        transition: all 0.2s ease-out;
    }
    .btn-primary {
        display: none;
    }
}

@media screen and (max-width: 960px) {

    .navbar-logo {
        font-size: 1.2rem;
        margin: 15px 5rem 0 0;
    }

}