:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
}

.btn:hover {
    padding: 8px 20px;
    transition: all 0.3s ease-out;
    background: rgba(255, 255, 255, 0.25);
    color: #fff;
    transition: 250ms;
}

.btn--primary {
    background-color: var(--primary);
}

.btn-solid {
    background-color: #552fffd1;
    color: #fff;
    font-size: large;
    margin: 1.5em;
}

.btn-solid:hover {
    background-color: #552fff84;
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border-radius: 4px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn-medium {
    padding: 8px 20px;
    border-radius: 4px;
    font-size: 18px;
    color: #552fff;
}

.btn-large {
    padding: 12px 20px;
    border-radius: 4px;
    font-size: 20px;
    color: #5d39ff;
}

@media screen and (max-width: 1024px) {

    .btn-solid {
        margin: none;
        font-size: small;
    }
  
  }
