.cardLayoutContainer {
    padding: 2vh 0 4vh 0;
}

.card {
    background-color: white;
    border-radius: 15px!important;
    transition: 0.5s;
    animation: ease-in-out;
    overflow: hidden;
}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 10px 0px;
}

.cardTitle {
    padding-top: 1rem;
    font-size: 3vh;
    font-weight: bolder;
    text-align: center;
    background-image: linear-gradient(90deg, rgba(62, 211, 208, 0.785) 5%, rgba(7,150,255,0.8939950980392157) 50%, rgba(93,57,255,0.8239670868347339) 95%);
    background-clip: text;
    color: transparent;
    padding-bottom: 0.25em;
}

.cardImg {
    width: 100%;
    border-radius: 15px 15px 0 0;
    transition: 0.5s;
    animation: ease-in-out;
}

.cardImg:hover {
    transform: scale(1.05);
}

.cardDescription {
    font-size: 2vh;
    font-weight: bold;
    color: rgb(92, 92, 92);
    padding: 1rem 1.5rem 1rem 1.5rem;
}

.card-footer {
    margin-top: 1rem;
    padding-bottom: 0.25rem;
    justify-content: center !important;
}

.card-link-heading {
    padding: 1rem;
    font-size: 2.5vh;
    font-weight: bolder;
    text-align: center;
}

.card-link {
    text-align: center;
    text-decoration: none;
    color:rgb(92, 92, 92);
    padding-bottom: 0.25em;
    transition: 0.5s;
    animation: ease-in-out;
}

.card-link:hover {
    background-image: linear-gradient(90deg, rgba(62, 211, 208, 0.785) 5%, rgba(7,150,255,0.8939950980392157) 50%, rgba(93,57,255,0.8239670868347339) 95%);
    background-clip: text;
    color: transparent;
    cursor: pointer;
}

@media screen and (max-width: 960px) {

    .card {
        margin: 0 1rem 0 1rem;
    }

}