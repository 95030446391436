.timelineContainer {
    padding-bottom: 3em;
    width: 100%;
}

.timelineContainer {
    background-color: #f0f0f8;
}

.date {
    color: rgb(0, 0, 0);
}

#experiencePage {
    background-color: #f0f0f8;
    width: 100%;
}