* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

.content {
  background-color: #F8F8FF;
  min-height: 100vh;
}

.pageTitle {
  font-size: 10vh;
  text-align: center;
  background-image: linear-gradient(90deg, rgba(62, 211, 208, 0.785) 5%, rgba(7,150,255,0.8939950980392157) 50%, rgba(93,57,255,0.8239670868347339) 95%);
  background-clip: text;
  color: transparent;
  padding-bottom: 0.25em;
}

@media screen and (max-width: 1024px) {

  .pageTitle {
      font-size: 4vh;
  }

}
